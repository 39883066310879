/* @import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css'); */
@import url('assets/styles/Privacy-Icons-Regular.min.css');
@import "src/assets/styles/mixins";
@import "assets/styles/bootstrap.min.css";
@import "assets/styles/material.scss";

:root {
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-f5f5f5: #F5F5F5;
  --unnamed-color-efefef: #efefef;
  --unnamed-color-d8d8d8: #D8D8D8;
  --unnamed-color-cbcbcb: #cbcbcb;

  --unnamed-color-4f4f4f: #4f4f4f;
  --line: #606060;
  --unnamed-color-ed696a: #ed696a;
  --unnamed-color-d02c2e: #d02c2e;
  --unnamed-color-992022: #992022;
  --unnamed-color-a1f292: #A1F292;
  --buttons-inputs: #2E2E2E;
  --unnamed-color-212121: #222222;
  --bg-dark: #181818;
  --warn-color: #FFA500FF;
}

* {
  outline: none;
}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'HK Grotesk', Roboto, 'Helvetica Neue', sans-serif;
}


// Text and list

h1, h2, h3, h4, h5, h6, div, span, li, small, input, textarea, select, p, strong {
  color: var(--unnamed-color-212121);
  font-family: 'HK Grotesk', Roboto, 'Helvetica Neue', sans-serif;
  margin: 0;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.fa, .fas, .fal, .far .fab, a {
  color: var(--unnamed-color-d02c2e);
}

small {
  color: var(--unnamed-color-4f4f4f);
  font-size: 12px;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Form controls

label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  span.text {
    font-weight: 600;
  }
}

input {
  font-size: 18px;
  font-weight: 400;
  border: 0;
  padding: 15px;

  &:disabled {
    cursor: unset;
  }
}

.button {
  user-select: none;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--unnamed-color-d02c2e);
  //padding: 10px 0;
  height: 43px;
  border-radius: 4px;
  color: var(--unnamed-color-d02c2e);
  background: transparent;
  font: 600 18px/23px HK Grotesk;

  @include md {
    min-width: 336px;
  }

  &:hover {
    border: 2px solid var(--unnamed-color-992022);

    span, i {
      color: var(--unnamed-color-992022);
    }
  }

  &:focus {
    box-shadow: 0 0 12px #CA2A2C;
  }

  &:disabled, &:disabled:active {
    border: 2px solid var(--unnamed-color-d8d8d8) !important;

    span, i {
      color: var(--unnamed-color-d8d8d8);
    }
  }

  &:active {
    border: 2px solid var(--unnamed-color-212121);

    span, i {
      color: var(--unnamed-color-212121);
    }
  }

  &[type=submit] {
    background: var(--unnamed-color-d02c2e);
    color: white;

    &:hover {
      border: 2px solid var(--unnamed-color-992022);
      background: var(--unnamed-color-992022);
    }

    &:disabled {
      pointer-events: none;
      background: var(--unnamed-color-d8d8d8);
    }

    &:active {
      background: var(--unnamed-color-212121);
    }

    i {
      color: white;
    }

    span {
      color: white;
    }
  }

  span {
    color: var(--unnamed-color-d02c2e);
    pointer-events: none;
  }

  i {
    margin-right: 10px;
  }

}

a {
  text-decoration: none;
  color: var(--unnamed-color-d02c2e);

  &:link {
    color: var(--unnamed-color-d02c2e);
  }

  &:hover {
    color: var(--unnamed-color-992022);
  }

  &:active {
    color: var(--unnamed-color-212121);
  }
}

.page-container {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  @include md {
    max-width: 682px;
    width: 682px;
  }
}

.page-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;

  @include md {
    padding-left: 0;
    padding-right: 0;
  }

  h4 {
    font-size: 22px;
    margin-bottom: 10px;

    @include md {
      font-size: 36px;
      font-weight: 700;
    }
  }

  h5 {
    font-size: 20px;
    margin-bottom: 10px;

    @include md {
      font-size: 28px;
      font-weight: 700;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 40px;

    @include md {
      font-size: 22px;
    }
  }

  small {
    font-size: 14px;
    display: block;
    margin-bottom: 20px;

    @include md {
      font-size: 18px;
    }
  }
}

.mat-mdc-checkbox.mat-accent {
  width: max-content;

  --mdc-checkbox-selected-checkmark-color: var(--unnamed-color-d02c2e);
  --mdc-checkbox-disabled-selected-checkmark-color: var(--unnamed-color-d02c2e);
  --mdc-checkbox-selected-focus-icon-color: transparent;
  --mdc-checkbox-selected-hover-icon-color: transparent;
  --mdc-checkbox-selected-icon-color: transparent;
  --mdc-checkbox-selected-pressed-icon-color: transparent;

  --mdc-checkbox-unselected-focus-icon-color: var(--unnamed-color-d02c2e);
  --mdc-checkbox-unselected-hover-icon-color: transparent;
  --mdc-checkbox-disabled-selected-icon-color: transparent;
  --mdc-checkbox-unselected-pressed-icon-color: transparent;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
  border-width: 1px;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
  border-color: var(--unnamed-color-d02c2e) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
  opacity: 0 !important;
}

.mdc-checkbox__background .mdc-checkbox__checkmark {
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: var(--unnamed-color-d02c2e);
  --mdc-chip-label-text-color: var(--unnamed-color-ffffff);
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  opacity: 1;
  padding-top: 3px;

  i {
    color: var(--unnamed-color-ffffff);
    font-size: 14px;
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: var(--unnamed-color-d02c2e);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--unnamed-color-d02c2e);
}

.mat-radio-label {
  flex-direction: row !important;
}

.mat-radio-container {
  width: 18px !important;
  height: 18px !important;
}

.mat-radio-inner-circle {
  width: 18px !important;
  height: 18px !important;
}

.mat-radio-outer-circle {
  width: 18px !important;
  height: 18px !important;
  border-width: 1px !important;
}

.mat-calendar-table {

  .mat-calendar-table-header-divider::after {
    height: 0;
  }

  [aria-hidden=true] .mat-calendar-body-label {
    display: none;
  }

  td.mat-calendar-body-label {
    visibility: hidden;
  }
}

.mat-calendar-body-selected {
  background: var(--unnamed-color-d02c2e) !important;
}

.mat-calendar-body-cell-content {
  border-width: 0 !important;
  font: 500 18px/23px HK Grotesk;
}

.mat-calendar-table-header th {
  font: 500 18px/23px HK Grotesk;
  color: #757575;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.no-items, .error-msg {
  display: flex;
  height: 130px;
  width: 100%;

  align-items: center;
  justify-content: center;
  color: var(--unnamed-color-4f4f4f);
}

.no-items {
  color: var(--unnamed-color-4f4f4f);
}

.error-msg {
  color: var(--unnamed-color-992022);
}

.dropdown-expanded-bg-gray {
  .dropdown-wrapper__body {
    background-color: var(--unnamed-color-efefef);
  }
}

.dropdown-padding-20 {
  .dropdown-wrapper__head {
    margin-left: 20px;
    margin-right: 20px;
  }

  .dropdown-wrapper__body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @include md {
    .dropdown-wrapper__head {
      margin-left: 0;
      margin-right: 0;
    }
    .dropdown-wrapper__body {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
}

.dropdown-wrapper.dropdown-light {
  width: 50%;
  margin-bottom: 5px;

  .dropdown-wrapper__head {
    padding: 0 0 5px 0;
    border-bottom: solid 2px var(--unnamed-color-efefef);

    &__title {
      font-weight: 500;
    }
  }

  .dropdown-wrapper__body + mat-divider {
    display: none;
  }
}

.mat-snack-bar {
  background: var(--unnamed-color-ffffff);
  font: 500 16px/21px HK Grotesk;

  button {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.circle {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;

  &.green {
    background: #00ff58;
    box-shadow: 0 0 5px #1aff69;
  }

  &.dark-green {
    background: #629d76;
    box-shadow: 0 0 5px #629d76;
  }

  &.red {
    background: #D02C2E;
    box-shadow: 0 0 5px #D02C2E;
  }

  &.yellow {
    background: #FFE941 0 0 no-repeat padding-box;
    box-shadow: 0 0 5px #FFE941;
  }

  &.blue {
    background: #7CCDFF;
    box-shadow: 0 0 5px #7CCDFF;
  }
}
